import { appConfig } from "@/constants";
import { EnvironmentNames, type Erc20Token } from "@/types";
import axios from "axios";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

export type ImportOrigin =
  | "CollectionsEmptyState"
  | "ActionMenuLinkWallet"
  | "ExternalWallets"
  | "ActionMenu"
  | "CollectionCard"
  | "ImportSuccess"
  | "ItemDetails";

export type ActiveAssetImport = {
  importAddress: string;
  trackingId: string;
};

export const Erc20Context = createContext<{
  tokens: Map<string, Erc20Token>;
  fetchToken: (address: string) => Erc20Token | undefined;
}>({ tokens: new Map(), fetchToken: () => undefined });

const useErc20 = () => {
  const ctx = useContext(Erc20Context);
  if (!ctx) {
    throw new Error("useErc20 must be used within a Erc20Provider");
  }
  return ctx;
};

const Erc20Provider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [tokenMap, setTokenMap] = useState<Map<string, Erc20Token>>(new Map());
  const baseUrl = useMemo(() => {
    const base =
      appConfig.ENVIRONMENT === EnvironmentNames.PRODUCTION
        ? "https://api.immutable.com"
        : "https://api.sandbox.immutable.com";
    const chainName =
      appConfig.ENVIRONMENT === EnvironmentNames.PRODUCTION
        ? "imtbl-zkevm-mainnet"
        : "imtbl-zkevm-testnet";
    return `${base}/v1/chains/${chainName}`;
  }, []);

  useEffect(() => {
    const getTokens = async () => {
      const path = `${baseUrl}/tokens?verification_status=verified&page_size=200`;
      const response = await axios.get(path);
      const tokens: Erc20Token[] = response.data.result;
      setTokenMap(
        new Map(tokens.map((token) => [token.contract_address, token])),
      );
    };
    getTokens();
  }, [baseUrl]);

  const fetchToken = useCallback(
    (address: string) => {
      if (tokenMap.has(address)) {
        return tokenMap.get(address);
      }

      const getSingleToken = async () => {
        try {
          const path = `${baseUrl}/tokens/${address}`;
          const response = await axios.get(path);
          const token: Erc20Token = response.data.result;
          // need to update the state in order for UI's to re-render
          const newMap = new Map(tokenMap);
          newMap.set(address, token);
          setTokenMap(newMap);
        } catch (e) {
          console.error(e);
        }
      };
      getSingleToken();

      return undefined;
    },
    [baseUrl, tokenMap],
  );

  return (
    <Erc20Context.Provider
      value={{
        tokens: tokenMap,
        fetchToken,
      }}
    >
      {children}
    </Erc20Context.Provider>
  );
};

export { Erc20Provider, useErc20 };
