import { ConfirmationDialog } from "@biom3/react";
import type { StandardAnalyticsActions } from "@imtbl/react-analytics";
import Image from "next/image";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { MODAL_WIDTH } from "@/constants";
import {
  type DashboardAnalyticsBaseType,
  useAnalytics,
  useBraze,
} from "@/context";
import type { ProfilePreferences } from "@/types";

const marketingConsentModalAnalytics: DashboardAnalyticsBaseType & {
  action: StandardAnalyticsActions;
} = {
  userJourney: "Onboarding",
  screen: "MarketingConsent",
  action: "Pressed",
};

const MarketingConsentModal = ({
  marketing_consent,
}: Pick<ProfilePreferences, "marketing_consent">) => {
  const [isModalVisible, setModalVisible] = useState(
    marketing_consent === "subscribed",
  );
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const { optIn, unsubscribe } = useBraze();

  useEffect(() => {
    if (isModalVisible) {
      track({
        ...marketingConsentModalAnalytics,
        control: "Onboarding",
        controlType: "Effect",
        action: "Request",
      });
    }
  }, [isModalVisible]);

  const onConfirm = () => {
    track({
      ...marketingConsentModalAnalytics,
      control: "Confirm",
      controlType: "Button",
    });
    setModalVisible(false);
    optIn();
  };

  const onReject = () => {
    track({
      ...marketingConsentModalAnalytics,
      control: "Reject",
      controlType: "Button",
    });
    setModalVisible(false);
    unsubscribe();
  };

  return (
    <ConfirmationDialog visible={isModalVisible}>
      <ConfirmationDialog.Content
        testId="MarketingConsentModal_Content"
        sx={{ width: MODAL_WIDTH }}
      >
        <ConfirmationDialog.Content.Image
          use={
            <Image src="/onboarding/hero.svg" width={430} height={287} alt="" />
          }
        />
        <ConfirmationDialog.Content.Title>
          {t("marketing_consent_request")}
        </ConfirmationDialog.Content.Title>
        <ConfirmationDialog.Content.Caption>
          {t("marketing_consent_body")}
        </ConfirmationDialog.Content.Caption>
        <ConfirmationDialog.Content.Button
          size="large"
          testId="MarketingConsentModal_Confirm"
          onClick={onConfirm}
        >
          {t("marketing_consent_confirm")}
        </ConfirmationDialog.Content.Button>
        <ConfirmationDialog.Content.Button
          size="large"
          onClick={onReject}
          testId="MarketingConsentModal_Reject"
          variant="tertiary"
        >
          {t("marketing_consent_reject")}
        </ConfirmationDialog.Content.Button>
      </ConfirmationDialog.Content>
    </ConfirmationDialog>
  );
};

export { MarketingConsentModal };
