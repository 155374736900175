import { Body, Box } from "@biom3/react";
import Image from "next/image";
import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { Loader } from "./Loader";
import { RoundedBox } from "./RoundedBox";

export function StatefulBox({
  children,
  error,
  isEmpty = false,
  isLoading = false,
  renderEmpty = false,
}: {
  children: ReactNode;
  error?: Error | null | unknown;
  isEmpty?: boolean;
  isLoading?: boolean;
  renderEmpty?: ReactNode;
}) {
  const { t } = useTranslation();

  if (error) {
    return (
      <Box
        testId="stateful-box-error"
        sx={{
          d: "flex",
          flexDirection: "column",
          mt: "base.spacing.x10",
          alignItems: "center",
          gap: "32px",
        }}
      >
        <Image
          priority
          src="/errorState.svg"
          alt="error state"
          style={{ maxWidth: "400px", maxHeight: "300.939px" }}
          width={400}
          height={300.939}
          layout="responsive"
        />
        <Body size="large" weight="bold">
          {t("stateful_error_heading")}
        </Body>
        <Body
          sx={{
            fontWeight: "base.text.body.large.regular.fontWeight",
            fontSize: "base.text.body.large.bold.fontSize",
          }}
        >
          {t("stateful_error_body")}
        </Body>
      </Box>
    );
  }
  if (isLoading) {
    return <Loader />;
  }
  if (isEmpty) {
    return (
      <RoundedBox
        testId="stateful-box-empty"
        sx={{
          borderRadius: "base.borderRadius.x6",
          textAlign: "left",
        }}
      >
        {renderEmpty ? (
          renderEmpty
        ) : (
          <Body testId="stateful-box-empty-label">
            {t("stateful_empty_body")}
          </Body>
        )}
      </RoundedBox>
    );
  }

  // "display: contents causes an element's children to appear as if they were direct children of the element's parent, ignoring the element itself. "
  return (
    <Box sx={{ display: "contents", height: "100%" }} testId="stateful-box">
      {children}
    </Box>
  );
}
