export type ErrorName =
  | "login"
  | "restoreSession"
  | "linkWallet"
  | "decodePassportToken"
  | "passportRegistration"
  | "appError"
  | "checkoutWidgetError"
  | "createERC721Listing"
  | "createERC1155Listing"
  | "getTotalFee"
  | "signAndSubmitApproval"
  | "signAndSubmitOrder"
  | "signListing"
  | "createListing"
  | "prepareERC1155Listing"
  | "prepareERC721Listing"
  | "getListings";

export function notifyError(
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  error: any,
  name: ErrorName,
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  customAttributes: any | undefined = undefined,
) {
  const attributes = customAttributes
    ? { errorName: name, ...customAttributes }
    : { errorName: name };

  if (typeof window !== "undefined") {
    // @ts-ignore
    window.newrelic?.noticeError(error, attributes);
  }
}

export function setNewRelicUserId(userId: string | null) {
  if (typeof window !== "undefined") {
    // @ts-ignore
    window.newrelic?.setUserId(userId);
  }
}
