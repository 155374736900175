import { useImmutableProvider } from "@/context/ImmutableProvider";
import { type ChainAddress, EnvironmentNames } from "@/types";
import type { Web3Provider } from "@ethersproject/providers";
import { orderbook } from "@imtbl/sdk";

import { appConfig } from "@/constants";
import { usePassportProvider } from "@/context/PassportProvider";
import { notifyError } from "@/utils/monitoring";
import { usePrevious } from "@biom3/react";
import { ImmutableERC721Abi, ImmutableERC1155Abi } from "@imtbl/contracts";
import { BigNumber } from "bignumber.js";
import {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { http, createPublicClient } from "viem";
import { immutableZkEvm, immutableZkEvmTestnet } from "viem/chains";

export interface OrderbookContextType {
  activeOrders: Map<string, orderbook.Order[]>;
  createERC721Listing: (params: ERC721ListingParams) => Promise<void>;
  prepareERC721Listing: (
    params: ERC721ListingParams,
  ) => Promise<orderbook.PrepareListingResponse>;
  createERC1155Listing: (params: ERC1155ListingParams) => Promise<void>;
  prepareERC1155Listing: (
    params: ERC1155ListingParams,
  ) => Promise<orderbook.PrepareListingResponse>;
  getTotalFee: (
    contractAddress: ChainAddress,
    tokenId: string,
    tokenType: string,
    salePrice: string,
  ) => Promise<string>;
  cancelListings: (orderIds: string[]) => Promise<void>;
}

export interface ERC721ListingParams {
  sellAddress: ChainAddress;
  sellItemTokenID: string;
  buyCurrencyType: string;
  buyItemAmount: string;
  buyItemContractAddress: string;
  makerAddress: string;
}

export interface ERC1155ListingParams {
  sellItemContractAddress: ChainAddress;
  sellItemTokenID: string;
  sellItemQty: string;
  buyCurrencyType: string;
  buyItemAmount: string;
  buyItemContractAddress: string;
  makerAddress: string;
}

const OrderbookContext = createContext<OrderbookContextType | undefined>(
  undefined,
);

const PROTOCOL_FEE = new BigNumber(2); // Represent 0.02 as 2
const SCALE = new BigNumber(100); // Scale factor to handle decimal places

export const getOrderKey = (contractAddress: string, tokenId: string) =>
  `${contractAddress}/${tokenId}`;

export const OrderbookProvider = ({ children }: { children: ReactNode }) => {
  const immutableProvider = useImmutableProvider();
  const { orderbookClient } = immutableProvider;
  const { zkEvmProvider, walletAddress } = usePassportProvider();
  const previousWalletAddress = usePrevious(walletAddress);
  const [activeOrders, setActiveOrders] = useState<
    Map<string, orderbook.Order[]>
  >(new Map());

  const getListings = useCallback(async () => {
    let pageCursor: string | null = null;
    const newOrders = new Map<string, orderbook.Order[]>();
    do {
      try {
        const listings = await orderbookClient.listListings({
          accountAddress: walletAddress,
          pageSize: 200,
          status: orderbook.OrderStatusName.ACTIVE,
          pageCursor: pageCursor ?? undefined,
        });
        for (const order of listings.result) {
          for (const item of order.sell) {
            newOrders.set(getOrderKey(item.contractAddress, item.tokenId), [
              ...(newOrders.get(item.contractAddress) || []),
              order,
            ]);
          }
        }
        pageCursor = listings.page.nextCursor;
      } catch (e) {
        notifyError(e, "getListings");
      }
    } while (pageCursor !== null);
    setActiveOrders(newOrders);
  }, [orderbookClient, walletAddress]);

  useEffect(() => {
    if (previousWalletAddress === walletAddress || !walletAddress) {
      return;
    }

    getListings();
  }, [getListings, walletAddress, previousWalletAddress]);

  const signAndSubmitApproval = async (
    provider: Web3Provider,
    listing: orderbook.PrepareListingResponse,
  ): Promise<void> => {
    const signer = provider.getSigner();
    const approvalAction = listing.actions.find(
      (action): action is orderbook.TransactionAction =>
        action.type === orderbook.ActionType.TRANSACTION,
    );

    if (approvalAction) {
      try {
        const unsignedTx = await approvalAction.buildTransaction();
        const receipt = await signer.sendTransaction(unsignedTx);
        await receipt.wait();
      } catch (e) {
        notifyError(e, "signAndSubmitApproval");
        throw new Error("Failed to sign and submit the approval transaction.");
      }
    }
  };

  const signListing = async (
    provider: Web3Provider,
    listing: orderbook.PrepareListingResponse,
  ): Promise<string> => {
    const signer = provider.getSigner();
    if (!signer) {
      throw new Error(
        "Signer is not available. Make sure the wallet is connected.",
      );
    }

    const signableAction = listing.actions.find(
      (action): action is orderbook.SignableAction =>
        action.type === orderbook.ActionType.SIGNABLE,
    );
    if (!signableAction) {
      throw new Error("No signable action found in the listing.");
    }

    try {
      return await signer._signTypedData(
        signableAction.message.domain,
        signableAction.message.types,
        signableAction.message.value,
      );
    } catch (e) {
      notifyError(e, "signListing");
      throw new Error("Failed to sign the listing.");
    }
  };

  const createListing = async (
    client: orderbook.Orderbook,
    preparedListing: orderbook.PrepareListingResponse,
    orderSignature: string,
  ): Promise<string> => {
    try {
      const order = await client.createListing({
        orderComponents: preparedListing.orderComponents,
        orderHash: preparedListing.orderHash,
        orderSignature,
        makerFees: [],
      });
      return order.result.id;
    } catch (e) {
      notifyError(e, "createListing");
      throw new Error("Failed to create the listing on the orderbook.");
    }
  };

  const handleSuccessfulListingCreation = async (listingID: string) => {
    let result: orderbook.ListingResult;
    let retries = 0;
    do {
      if (retries > 0) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      result = await orderbookClient.getListing(listingID);
    } while (result.result.status.name !== "ACTIVE" && retries++ < 5);
    getListings();
    console.log(`Listing created successfully - ${listingID}`);
  };

  const prepareERC721Listing = async ({
    sellAddress,
    sellItemTokenID,
    buyCurrencyType,
    buyItemAmount,
    buyItemContractAddress,
    makerAddress,
  }: ERC721ListingParams): Promise<orderbook.PrepareListingResponse> => {
    try {
      // build the sell item
      const sell: orderbook.ERC721Item = {
        contractAddress: sellAddress,
        tokenId: sellItemTokenID,
        type: "ERC721",
      };

      // build the buy item
      const buy =
        buyCurrencyType === "NATIVE"
          ? ({
              amount: buyItemAmount,
              type: "NATIVE",
            } as orderbook.NativeItem)
          : ({
              amount: buyItemAmount,
              type: "ERC20",
              contractAddress: buyItemContractAddress,
            } as orderbook.ERC20Item);

      // build the prepare listing parameters
      const prepareListingParams: orderbook.PrepareListingParams = {
        makerAddress,
        buy,
        sell,
      };

      // invoke the orderbook SDK to prepare the listing
      return await orderbookClient.prepareListing(prepareListingParams);
    } catch (e) {
      notifyError(e, "prepareERC721Listing");
      throw new Error("Failed to prepare ERC721 listing.");
    }
  };

  // Implementation for creating ERC721 listing
  const createERC721Listing = async (params: ERC721ListingParams) => {
    if (!zkEvmProvider) {
      console.error("zkEvmProvider is not available");
      return;
    }

    try {
      // prepare the listing
      const preparedListing = await prepareERC721Listing(params);

      // sign and submit approval transaction
      await signAndSubmitApproval(zkEvmProvider, preparedListing);

      // sign the listing
      const orderSignature = await signListing(zkEvmProvider, preparedListing);

      // create the listing
      const listingID = await createListing(
        orderbookClient,
        preparedListing,
        orderSignature,
      );

      handleSuccessfulListingCreation(listingID);
    } catch (e) {
      notifyError(e, "createERC721Listing");
      if (e instanceof Error && e.message) {
        throw new Error(`${e.message}`);
      }
      throw new Error("Failed to create ERC721 listing.");
    }
  };

  // Implementation for preparing ERC1155 listing
  const prepareERC1155Listing = async ({
    sellItemContractAddress,
    sellItemTokenID,
    sellItemQty,
    buyCurrencyType,
    buyItemAmount,
    buyItemContractAddress,
    makerAddress,
  }: ERC1155ListingParams): Promise<orderbook.PrepareListingResponse> => {
    try {
      // build the sell item
      const sell: orderbook.ERC1155Item = {
        contractAddress: sellItemContractAddress,
        tokenId: sellItemTokenID,
        amount: sellItemQty,
        type: "ERC1155",
      };

      // build the buy item
      const buy =
        buyCurrencyType === "NATIVE"
          ? ({
              amount: buyItemAmount,
              type: "NATIVE",
            } as orderbook.NativeItem)
          : ({
              amount: buyItemAmount,
              type: "ERC20",
              contractAddress: buyItemContractAddress,
            } as orderbook.ERC20Item);

      // build the prepare listing parameters
      const prepareListingParams: orderbook.PrepareListingParams = {
        makerAddress,
        buy,
        sell,
      };

      // invoke the orderbook SDK to prepare the listing
      return await orderbookClient.prepareListing(prepareListingParams);
    } catch (e) {
      notifyError(e, "prepareERC1155Listing");
      throw new Error("Failed to prepare ERC1155 listing.");
    }
  };

  // Implementation for creating ERC1155 listing
  const createERC1155Listing = async (params: ERC1155ListingParams) => {
    if (!zkEvmProvider) {
      console.error("zkEvmProvider is not available");
      return;
    }

    try {
      // prepare the listing
      const preparedListing = await prepareERC1155Listing(params);

      // sign and submit approval transaction
      await signAndSubmitApproval(zkEvmProvider, preparedListing);

      // sign the listing
      const orderSignature = await signListing(zkEvmProvider, preparedListing);

      // create the listing
      const listingID = await createListing(
        orderbookClient,
        preparedListing,
        orderSignature,
      );

      handleSuccessfulListingCreation(listingID);
    } catch (e) {
      notifyError(e, "createERC1155Listing");
      if (e instanceof Error && e.message) {
        throw new Error(`${e.message}`);
      }
      throw new Error("Failed to create ERC1155 listing.");
    }
  };
  const activeChain =
    appConfig.ENVIRONMENT === EnvironmentNames.PRODUCTION
      ? immutableZkEvm
      : immutableZkEvmTestnet;

  const getTotalFee = async (
    contractAddress: ChainAddress,
    tokenId: string,
    tokenType: string,
    salePrice: string,
  ) => {
    const ABI =
      tokenType === "ERC721" ? ImmutableERC721Abi : ImmutableERC1155Abi;
    try {
      const salePriceBigInt = BigInt(salePrice);
      const tokenIdBigInt = BigInt(tokenId);

      const publicClient = createPublicClient({
        chain: activeChain,
        transport: http(),
      });
      const royaltyInfo = await publicClient.readContract({
        abi: ABI,
        address: contractAddress,
        functionName: "royaltyInfo",
        args: [tokenIdBigInt, salePriceBigInt],
      });

      const royaltyAmount = new BigNumber(royaltyInfo[1].toString());
      const protocolFee = new BigNumber(salePrice)
        .multipliedBy(PROTOCOL_FEE)
        .dividedBy(SCALE);

      // the protocol fee is 2% of the sale price: https://docs.immutable.com/products/zkEVM/orderbook/fees#protocol-fees
      // view the royalty info: https://docs.immutable.com/products/zkEVM/minting/royalties/setting#view-a-collections-royalty-information
      const totalFee = royaltyAmount.plus(protocolFee);
      return totalFee.toFixed(0);
    } catch (e) {
      // if the asset does not have royaltyInfo, it throws an error
      notifyError(e, "getTotalFee");
      return "0";
    }
  };

  const cancelListings = async (orderIds: string[]) => {
    if (walletAddress === undefined) {
      throw new Error("Wallet address is not available");
    }

    const signer = zkEvmProvider?.getSigner();
    if (signer === undefined) {
      throw new Error("Signer is not available");
    }

    const preparedCancellations =
      await orderbookClient.prepareOrderCancellations(orderIds);

    const signature = await zkEvmProvider
      ?.getSigner()
      ?._signTypedData(
        preparedCancellations.signableAction.message.domain,
        preparedCancellations.signableAction.message.types,
        preparedCancellations.signableAction.message.value,
      );

    if (signature === undefined) {
      throw new Error("Failed to sign the cancellation");
    }

    const cancellation = await orderbookClient.cancelOrders(
      orderIds,
      walletAddress,
      signature,
    );
    if (cancellation.result.failed_cancellations.length > 0) {
      throw new Error("Failed to cancel the order");
    }

    getListings();
    return;
  };

  return (
    <OrderbookContext.Provider
      value={{
        activeOrders,
        prepareERC721Listing,
        createERC721Listing,
        prepareERC1155Listing,
        createERC1155Listing,
        getTotalFee,
        cancelListings,
      }}
    >
      {children}
    </OrderbookContext.Provider>
  );
};

export const useOrderbook = (): OrderbookContextType => {
  const context = useContext(OrderbookContext);
  if (!context) {
    throw new Error("useOrderbook must be used within an OrderbookProvider");
  }
  return context;
};
