import {
  type StandardAnalyticsActions,
  type StandardAnalyticsControlTypes,
  createAnalytics,
} from "@imtbl/react-analytics";

import { appConfig } from "@/constants";

export type DashboardAnalyticsJourneyType =
  | "Profile"
  | "Activity"
  | "Balances"
  | "Collections"
  | "Games"
  | "Wallets"
  | "AssetTransfer"
  | "Rewards"
  | "Onboarding"
  | "Gems"
  | "Split"
  | "Listing";

export type DashboardAnalyticsScreenType = string;
export type DashboardAnalyticsControlType = string;

export type DashboardAnalyticsBaseType = {
  userJourney: DashboardAnalyticsJourneyType;
  screen: DashboardAnalyticsScreenType;
};

export const { AnalyticsProvider, useAnalytics } = createAnalytics<
  DashboardAnalyticsJourneyType,
  DashboardAnalyticsScreenType,
  DashboardAnalyticsControlType,
  StandardAnalyticsControlTypes | "Effect" | "Card" | "Modal" | "Group",
  StandardAnalyticsActions | "Changed" | "Closed"
>({
  writeKey: appConfig.SEGMENT_KEY,
  appName: "PassportDashboard",
});
